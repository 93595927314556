import React from 'react'
import './Avatar.css'
import defaultAvatar from 'assets/icons/avatar.svg'

interface Props {
  url?: string
}

const Avatar = ({ url = '' }: Props) => (
  <img src={url || defaultAvatar} alt="courier" className="courier-avatar" />
)

export default Avatar
