import React from 'react'
import './Spinner.css'

interface Props {
  small?: boolean
}

const Spinner = ({ small = false }: Props) => {
  const size: number = small ? 20 : 80

  return (
    <svg
      className="spinner"
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 50 50"
      style={{ margin: `-${size / 2}px 0 0 -${size / 2}px` }}
    >
      <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
    </svg>
  )
}

export default Spinner
