import { useRef, RefObject } from 'react'
import i18n from 'locales/i18n'
import { bbox, featureCollection, point } from '@turf/turf'
import {
  Map,
  Layer,
  GeoJSONSourceOptions,
  Marker,
  LngLatBoundsLike,
  PaddingOptions
} from 'mapbox-gl'

import PositionBtn from './PositionBtn'

interface Args {
  container: RefObject<HTMLDivElement>
  padding: number | PaddingOptions
  isTestMode: boolean
}

interface IMapbox {
  map: RefObject<null | Map>
  mapAPI: {
    initMap: () => void
    addDirectionLayer: (data?: GeoJSONSourceOptions['data']) => void
    addMarker: (marker: Marker | null) => void
    addPositionBtn: (bounds: LngLatBoundsLike) => void
    getBounds: (markers: Marker[]) => LngLatBoundsLike
    setBounds: (bounds: LngLatBoundsLike) => void
    removeDirection: () => void
  }
}

const CENTER_FRANCE: [number, number] = [1.85, 46.63]
const ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
const DEFAULT_ROUTE_DATA = {
  type: 'FeatureCollection',
  features: []
} as GeoJSONSourceOptions['data']

export default function useMapbox({ container, padding, isTestMode }: Args): IMapbox {
  const map = useRef<null | Map>(null)

  const changeMapLanguage = (): void => {
    const language = i18n.language.split('-')[0]
    map.current?.getStyle().layers.forEach((layer: Layer) => {
      if (layer.id.endsWith('-label')) {
        map.current?.setLayoutProperty(layer.id, 'text-field', ['get', `name_${language}`])
      }
    })
  }

  const initMap = (): void => {
    if (isTestMode && container?.current) {
      map.current = new Map({
        container: container.current,
        testMode: true,
        style: {
          version: 8,
          sources: {},
          layers: [
            {
              id: 'background',
              type: 'background',
              paint: {
                'background-color': 'white'
              }
            }
          ]
        }
      })
    } else if (!isTestMode && container?.current) {
      map.current = new Map({
        container: container.current,
        accessToken: ACCESS_TOKEN,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: CENTER_FRANCE,
        zoom: 6
      })
    }

    map.current?.on('load', () => {
      changeMapLanguage()
    })
  }

  const addDirectionLayer = (data: GeoJSONSourceOptions['data'] = DEFAULT_ROUTE_DATA): void => {
    map.current?.on('load', () => {
      map.current?.addSource('route', { type: 'geojson', data })

      map.current?.addLayer({
        id: 'route',
        type: 'line',
        source: 'route',
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': '#3887be',
          'line-width': 4
        }
      })
    })
  }

  const addMarker = (marker: Marker | null): void => {
    if (map?.current && marker) marker.addTo(map.current)
  }

  const addPositionBtn = (bounds: LngLatBoundsLike): void => {
    const centerPositionCtrl = new PositionBtn({ bounds, padding })
    map.current?.addControl(centerPositionCtrl, 'bottom-right')
  }

  const getBounds = (markers: Marker[]): LngLatBoundsLike => {
    const features = featureCollection(
      Array.from(markers, (marker) => point(marker.getLngLat().toArray()))
    )
    const [minX, minY, maxX, maxY] = bbox(features)
    return [
      [minX, minY],
      [maxX, maxY]
    ] as LngLatBoundsLike
  }

  const setBounds = (bounds: LngLatBoundsLike): void => {
    map.current?.fitBounds(bounds, { padding })
  }

  const removeDirection = (): void => {
    map.current?.removeLayer('route')
    map.current?.removeSource('route')
  }

  return {
    map,
    mapAPI: {
      initMap,
      addDirectionLayer,
      addMarker,
      addPositionBtn,
      getBounds,
      setBounds,
      removeDirection
    }
  }
}
