import React from 'react'

import './Loader.css'
import Spinner from '../spinner/Spinner'

interface Props {
  overlay?: boolean
}

const Loader = ({ overlay = false }: Props) => (
  <div className="loader-container">
    <div className={overlay ? 'overlay' : undefined} />
    <Spinner />
  </div>
)

export default Loader
