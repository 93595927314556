import React from 'react'
import { Trans } from 'react-i18next'

interface Props {
  number: string
  i18nKey: string
}

const RefNumber = ({ number, i18nKey }: Props) => (
  <Trans i18nKey={i18nKey}>
    <span className="fs-small" />
    <b />
    {{ number }}
  </Trans>
)

export default RefNumber
