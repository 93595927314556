import { GeoJSONSourceOptions } from 'mapbox-gl'
import http from './http'

interface Params {
  from: number[]
  to: number[]
}
interface GeoParams {
  from: string
  to: string
}

interface ApiParams {
  [key: string]: string | undefined
}

export interface IDirection {
  duration: number
  route: GeoJSONSourceOptions['data']
}

const useMapbox = ({ from, to }: GeoParams) => {
  let url = `https://api.mapbox.com/directions/v5/mapbox/driving/${from};${to}`

  const params: ApiParams = {
    access_token: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
    geometries: 'geojson'
  }

  const queryParams = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&')
  url = `${url}?${queryParams}`

  return http.get({ url })
}

export default async ({ from, to }: Params): Promise<IDirection | undefined> => {
  const useParams = {
    from: from.join(','),
    to: to.join(',')
  }

  try {
    const { routes } = await useMapbox(useParams)
    const { geometry, duration } = routes[0]
    return {
      duration,
      route: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: geometry.coordinates
        }
      }
    }
  } catch (error) {
    return undefined
  }
}
