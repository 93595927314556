import { IControl, LngLatBoundsLike, Map, PaddingOptions } from 'mapbox-gl'
import Focus from 'assets/icons/focus.svg'

interface Params {
  bounds: LngLatBoundsLike
  padding: number | PaddingOptions
}

export default class PositionBtn implements IControl {
  _bounds: LngLatBoundsLike

  _padding: number | PaddingOptions

  _container: HTMLDivElement

  _map: Map | null

  constructor({ bounds, padding }: Params) {
    this._bounds = bounds
    this._padding = padding
    this._container = document.createElement('div')
    this._map = null
  }

  onAdd(map: Map) {
    this._map = map
    const img = document.createElement('img')
    img.src = Focus

    const btn = document.createElement('button')
    btn.className = 'mapboxgl-ctrl-icon'
    btn.type = 'button'
    btn.title = 'recentrer'
    btn.onclick = () => map.fitBounds(this._bounds, { padding: this._padding })
    btn.appendChild(img)

    this._container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl'
    this._container.appendChild(btn)

    return this._container
  }

  onRemove() {
    this._container.parentNode?.removeChild(this._container)
    this._map = null
  }
}
