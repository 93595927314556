import React, { ReactNode } from 'react'

import './TabContent.css'

type Props = {
  label: string
  children: ReactNode
}

const TabContent = ({ children, label }: Props) => (
  <div className="tab-content" aria-label={label}>
    {children}
  </div>
)

export default TabContent
