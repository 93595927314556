import React, { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

import './TrackingForm.css'
import { useToggleClass } from 'hooks'
import trackingAPI from 'api/tracking'
import DefaultLogo from 'assets/tc_logo_v1.svg'
import { ITrackingData } from 'interfaces'
import Spinner from 'components/spinner/Spinner'

interface Props {
  updateTrackingData(data: ITrackingData): void
}

const TrackingForm = ({ updateTrackingData }: Props) => {
  const { t } = useTranslation()
  const isShowed = useToggleClass()
  const [trackingId, setTrackingId] = useState<string>()
  const [status, setStatus] = useState<'idle' | 'loading'>('idle')
  const [error, setError] = useState<string>()

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (trackingId) {
      setStatus('loading')
      trackingAPI
        .infos({ id: trackingId })
        .then((data) => {
          if (data.status === 'closed') {
            setError(t('errors.expired'))
          } else {
            window.history.pushState('', '', trackingId)
            updateTrackingData(data)
          }
        })
        .catch(() => setError(t('errors.expired')))
        .finally(() => setStatus('idle'))
    } else {
      setError(t('errors.emptyTrackingId'))
    }
  }

  return (
    <div className={`tracking-form-container fade-in up ${isShowed ? 'show' : null}`}>
      <div className="header">
        <img src={DefaultLogo} alt="top-chrono" />
      </div>
      <form className="tracking-form" onSubmit={onSubmit}>
        <div className="label-input">
          <input
            id="trackingId"
            name="trackingId"
            type="test"
            className={error ? 'error' : undefined}
            autoComplete="off"
            placeholder="0ZYk5-exemple"
            onChange={({ target }) => setTrackingId(target.value)}
          />
          <span className="highlight" />
          <span className="bar" />
          <label htmlFor="trackingId" className={error ? 'error' : undefined}>
            {t('trackingForm.trackingIdLabel')}
          </label>
        </div>
        <div className="input-error">{error}</div>
        {status && (
          <button type="submit" className="shiny-btn">
            {status === 'loading' ? (
              <>
                <Spinner small />
                <span style={{ visibility: 'hidden' }}>{t('trackingForm.submitButton')}</span>
              </>
            ) : (
              t('trackingForm.submitButton')
            )}
          </button>
        )}
      </form>
    </div>
  )
}

export default TrackingForm
