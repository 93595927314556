import React from 'react'

import './AttachmentItem.css'
import FileDownload from 'assets/icons/file-download.svg'
import IAttachment from 'interfaces/attachment'

interface Props {
  attachment: IAttachment
}

const AttachmentItem = ({ attachment }: Props) => {
  const { url, fileLabel } = attachment
  return (
    <div className="download-container">
      <a className="td-none fc-secondary" href={url} download={fileLabel}>
        <img src={FileDownload} alt="download file icon" />
        <div>{fileLabel}</div>
      </a>
    </div>
  )
}

export default AttachmentItem
