import { useState, useEffect } from 'react'

interface Breakpoint {
  [key: string]: number
}
// iso with bootstrap
const BREAKPOINTS: Breakpoint = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height
  }
}

export default function useMediaBreakpointUp() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  const mediaBreakpointUp = (size: string) => {
    if (!BREAKPOINTS[size]) {
      throw new Error(`${size} is not a valid breakpoint`)
    }

    return windowDimensions.width > BREAKPOINTS[size]
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return mediaBreakpointUp
}
