import React from 'react'
import { Helmet } from 'react-helmet'

const Analytics = () => (
  <Helmet>
    {/* Matomo Tag Manager */}
    <script type="text/javascript">
      {`
        var _mtm = window._mtm = window._mtm || [];
        _mtm.push({
          'startTime': (new Date().getTime()),
          'event': 'mtm.Start'
        });
      `}
    </script>
    <script src={process.env.REACT_APP_MATOMO_SRC} async />
  </Helmet>
)

export default Analytics
