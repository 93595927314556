import React, { ReactElement, useState } from 'react'
import './Tabs.css'

interface Props {
  children: ReactElement[]
}

const Tabs = ({ children }: Props) => {
  const [activeTab, setActiveTab] = useState<number>(0)

  return (
    <div className="tabs-container bg-main-color">
      <div className="tabs-list bg-white">
        {children?.map((child, index) => {
          const { label }: { label: string } = child.props
          if (label) {
            return (
              <div key={label} className="tab">
                <button
                  key={label}
                  type="button"
                  className={`${activeTab === index ? 'active' : ''}`}
                  onClick={() => setActiveTab(index)}
                >
                  {label}
                </button>
                <div className={`${activeTab === index ? 'active' : ''}`} />
              </div>
            )
          }

          return null
        })}
      </div>
      {children[activeTab]}
    </div>
  )
}

export default Tabs
