interface GetParams {
  url: string
  headers?: HeadersInit
}

interface RequestParams extends GetParams {
  method: string
  body?: unknown
}

interface Config {
  method: string
  headers: Headers
  body?: string
}

const parseResponse = async (response: Response) => {
  const { status } = response
  let data

  if (status !== 204) {
    data = await response.json()
  }

  return {
    status,
    data
  }
}

const request = async (params: RequestParams) => {
  const { method = 'GET', url, headers = {}, body } = params

  const config: Config = {
    method,
    headers: new window.Headers(headers)
  }

  if (body) {
    config.body = JSON.stringify(body)
  }

  const response = await window.fetch(url, config)
  const formattedResponse = await parseResponse(response)

  if (!response.ok) {
    const { data } = formattedResponse
    const error = (data && data.message) || formattedResponse.status
    return Promise.reject(error)
  }

  return formattedResponse
}

const get = async ({ url, headers }: GetParams) => {
  const response = await request({
    url,
    headers,
    method: 'GET'
  })

  return response.data
}

export default {
  get
}
