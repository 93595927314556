import { ITrackingData } from 'interfaces'
import { isEqual } from 'lodash-es'

interface Args {
  trackingData: ITrackingData
  newTrackingData: ITrackingData
}

const hasTrackingDataChanged = ({ trackingData, newTrackingData }: Args): boolean => {
  const filteredData = {
    ...trackingData,
    courier: { ...trackingData.courier, url: '' },
    connectionToken: ''
  }
  const filteredNewData = {
    ...newTrackingData,
    courier: { ...newTrackingData.courier, url: '' },
    connectionToken: ''
  }

  return !isEqual(filteredData, filteredNewData)
}

export default hasTrackingDataChanged
