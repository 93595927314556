import { ICoordinates } from 'interfaces'
import { Marker } from 'mapbox-gl'

interface Args {
  marker: Marker
  coordinates: ICoordinates
}

const didCourierMove = ({ marker, coordinates }: Args): boolean =>
  marker.getLngLat().lng !== coordinates.longitude ||
  marker.getLngLat().lat !== coordinates.latitude

export default didCourierMove
