import React, { ReactNode } from 'react'
import { Trans } from 'react-i18next'

import { ITrackingData } from 'interfaces'
import AttachmentsContainer from '../attachment-container/AttachmentsContainer'
import AttachmentItem from '../attachment-item/AttachmentItem'
import DatetimeContainer from '../datetime-container/DatetimeContainer'
import EventHistory from '../event-history/EventHistory'
import RefContainer from '../ref-container/RefContainer'

interface Props {
  trackingData: ITrackingData
  estimatedTime: string | undefined
  scheduledTime: {
    startDate: string
    startHour: string
    endHour: string
  }
  children: ReactNode
}

const InfosSmallscreen = ({ trackingData, estimatedTime, scheduledTime, children }: Props) => {
  const { eventList = [], toAddress, attachments } = trackingData
  const { startDate, startHour, endHour } = scheduledTime

  return (
    <>
      <div className="border-top" />
      {estimatedTime ? (
        <DatetimeContainer classNames="scheduled">
          <Trans i18nKey="deliveryDatetime.largeScreen">
            <span className="fs-small" />
            <span className="fw-semibold" />
            {{ startDate }}
            {{ startHour }}
            {{ endHour }}
          </Trans>
        </DatetimeContainer>
      ) : null}
      <EventHistory eventList={eventList} address={toAddress.address} />
      <RefContainer trackingData={trackingData} />
      {attachments?.length && (
        <AttachmentsContainer>
          {attachments.map((attachment) => (
            <AttachmentItem key={attachment.fileLabel} attachment={attachment} />
          ))}
        </AttachmentsContainer>
      )}
      {children}
    </>
  )
}

export default InfosSmallscreen
