import { Marker } from 'mapbox-gl'

import { ICoordinates } from '../interfaces'
import formatCoordinates from './formatCoordinates'

interface Params {
  kind: 'to' | 'from' | 'truck'
  coordinates: ICoordinates
}

const createElement = (icon: Params['kind']) => {
  const el = document.createElement('div')
  el.className = `marker map-${icon}-marker`

  return el
}

export default ({ kind, coordinates: jsonCoordinates }: Params): Marker | null => {
  const coordinates = formatCoordinates.toArray(jsonCoordinates)
  const element = createElement(kind)

  if (!coordinates[0] || !coordinates[1]) return null
  return new Marker(element).setLngLat(coordinates)
}
