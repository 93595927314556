import http from './http'

interface InfosArgs {
  id: string
  token?: string
}
interface LocationArgs {
  connectionToken: string
  shipmentId: string
}

const BASE_URL = process.env.REACT_APP_API_URL

const infos = ({ id, token }: InfosArgs) => {
  let url = `${BASE_URL}/v1/shipment?trackingId=${id}`
  url = token ? `${url}&connectionToken=${token}` : url

  return http.get({ url }).then((data) => ({ ...data, trackingId: id }))
}

const location = ({ connectionToken, shipmentId }: LocationArgs) =>
  http.get({
    url: `${BASE_URL}/v1/location?connectionToken=${connectionToken}&shipmentId=${shipmentId}`
  })

export default {
  infos,
  location
}
