import React, { Trans } from 'react-i18next'
import clock from 'assets/images/clock.svg'
// styles come from global-styles.css

const Error = () => (
  <div className="gs-container-illustration">
    <div className="gs-box-illustration">
      <div className="gs-content-illustration pre-line">
        <Trans i18nKey="trackingError">
          <b />
        </Trans>
        <img src={clock} className="gs-illustration" alt="clock" />
      </div>
    </div>
  </div>
)

export default Error
