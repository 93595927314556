import React, { ReactNode } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { ITrackingData } from 'interfaces'
import AttachmentsContainer from '../attachment-container/AttachmentsContainer'
import AttachmentItem from '../attachment-item/AttachmentItem'
import DatetimeContainer from '../datetime-container/DatetimeContainer'
import EventHistory from '../event-history/EventHistory'
import RefContainer from '../ref-container/RefContainer'
import TabContent from '../tab-content/TabContent'
import Tabs from '../tabs/Tabs'

interface Props {
  trackingData: ITrackingData
  estimatedTime: string | undefined
  scheduledTime: {
    startDate: string
    startHour: string
    endHour: string
  }
  children: ReactNode
}
const InfosLargescreen = ({ trackingData, estimatedTime, scheduledTime, children }: Props) => {
  const { t } = useTranslation()
  const { eventList = [], toAddress, attachments } = trackingData
  const { startDate, startHour, endHour } = scheduledTime

  return (
    <Tabs>
      <TabContent label={t('history')}>
        <div className="tracking-infos">
          <DatetimeContainer classNames="scheduled">
            <Trans i18nKey="deliveryDatetime.largeScreen">
              <span className="fs-small" />
              <span className="fw-semibold" />
              {{ startDate }}
              {{ startHour }}
              {{ endHour }}
            </Trans>
          </DatetimeContainer>
          {estimatedTime ? (
            <DatetimeContainer classNames="estimated">
              <Trans i18nKey="deliveryEstimated.largeScreen">
                <span className="fs-small" />
                <span className="fw-semibold fs-big fc-estimated-time" />
                {{ estimatedTime }}
              </Trans>
            </DatetimeContainer>
          ) : null}
        </div>
        <EventHistory eventList={eventList} address={toAddress.address} />
      </TabContent>
      <TabContent label={t('informations')}>
        <RefContainer trackingData={trackingData} />
        {children}
      </TabContent>
      <TabContent label={t('attachments')}>
        {attachments?.length ? (
          <AttachmentsContainer largeScreen>
            {attachments.map((attachment) => (
              <AttachmentItem key={attachment.fileLabel} attachment={attachment} />
            ))}
          </AttachmentsContainer>
        ) : (
          <div className="tab-content-center">{t('noDocuments')}</div>
        )}
      </TabContent>
    </Tabs>
  )
}

export default InfosLargescreen
