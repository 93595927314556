import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import './EventHistory.css'
import { IEvent } from 'interfaces'

interface Props {
  eventList: IEvent[]
  address: string
}

interface Steps {
  [key: string]: string[]
}

const nextSteps: Steps = {
  pickedUp: ['delivered'],
  create: ['delivered', 'pickedUp']
}

const EventHistory = ({ eventList, address }: Props) => {
  const [lastEvent, setLastEvent] = useState<string | undefined>()
  const { t } = useTranslation()

  const itemClass = (event: IEvent): string => {
    const classList = ['timeline-item']
    if (event.type !== 'litigation') classList.push('done')
    if (event.name === lastEvent && event.name !== 'delivered' && event.type !== 'litigation')
      classList.push('last-event')
    if (event.name === lastEvent && event.type === 'litigation')
      classList.push('litigation-last-event')

    return classList.join(' ')
  }

  useEffect(() => {
    if (eventList.length) {
      eventList.sort((a, b) => Date.parse(b.date) - Date.parse(a.date))
      setLastEvent(eventList[0].name)
    }
  }, [eventList])

  return (
    <div className="timeline-container">
      {lastEvent && nextSteps[lastEvent]?.length
        ? nextSteps[lastEvent].map((event) => (
            <div key={`next.${event}`} className="timeline-item todo fc-light">
              <div className="timeline-item-content fw-semibold">{t(`nextEvent.${event}`)}</div>
              {event === 'delivered' ? <div>{address}</div> : null}
              <span className="circle" />
            </div>
          ))
        : null}
      {eventList.map((event) => {
        const hour: string = dayjs(event.date).format('HH:mm')
        const date: string = dayjs(event.date).format('ddd D MMMM')
        if (event.name === 'nearDropOff' && lastEvent !== 'nearDropOff') return null

        return (
          <div key={event.name} className={itemClass(event)}>
            <div className="timeline-item-content fw-semibold">{t(`event.${event.name}`)}</div>
            <div className="fs-small">{`${date} - ${hour}`}</div>
            {event.type === 'litigation' ? (
              <svg
                className="warning-circle"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-7v2h2v-2h-2zm0-8v6h2V7h-2z" />
              </svg>
            ) : null}
            <span className="circle" />
          </div>
        )
      })}
    </div>
  )
}

export default EventHistory
