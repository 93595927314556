import React, { useState, useRef, useEffect, useMemo } from 'react'
import { Marker, GeoJSONSource } from 'mapbox-gl'

import { markerFactory, didCourierMove, formatCoordinates } from 'utils/'
import { ITrackingConfig, ITrackingData } from 'interfaces'
import { useMediaBreakpointUp, useInterval, useMapbox } from 'hooks'
import direction, { IDirection } from 'api/direction'
import tracking from 'api/tracking'
import './TrackingMap.css'
import Loader from '../loader/Loader'

interface Props {
  trackingData: ITrackingData | undefined
  trackingConfig: ITrackingConfig
  updateEstimatedTime(newDuration?: number): void
  isTestMode: boolean
}

const TrackingMap = ({ trackingData, trackingConfig, updateEstimatedTime, isTestMode }: Props) => {
  const container = useRef<HTMLDivElement | null>(null)
  const toMarker = useRef<Marker | null>(null)
  const fromMarker = useRef<Marker | null>(null)
  const courierMarker = useRef<Marker | null>(null)
  const isDirectionLayerLoaded = useRef<boolean>(false)
  const isDataLoaded = useRef<boolean>(false)

  const mediaBreakpointUp = useMediaBreakpointUp()
  const padding = mediaBreakpointUp('xl')
    ? { top: 80, bottom: 50, left: 750, right: 150 }
    : { top: 80, bottom: 400, left: 50, right: 50 }

  const { map, mapAPI } = useMapbox({
    container,
    padding,
    isTestMode
  })
  const [status, setStatus] = useState<'pending' | 'ready'>('pending')

  const useCourierPosition: boolean = useMemo(() => {
    const lastEventName = trackingData?.eventList?.sort(
      (a, b) => Date.parse(b.date) - Date.parse(a.date)
    )[0]?.name

    return trackingConfig.enableCourierPosition && lastEventName === 'nearDropOff'
  }, [trackingConfig, trackingData])

  const getDirection = async ({
    from,
    to
  }: {
    from: Marker
    to: Marker
  }): Promise<IDirection | undefined> => {
    const routeData = await direction({
      from: from.getLngLat().toArray(),
      to: to.getLngLat().toArray()
    })

    return routeData
  }

  const updateDirection = async ({ from, to }: { from: Marker; to: Marker }): Promise<void> => {
    const { duration, route } = (await getDirection({ from, to })) || {}
    if (route && isDirectionLayerLoaded.current) {
      const source = map.current?.getSource('route') as GeoJSONSource
      source.setData(route)
    } else if (route) {
      mapAPI.addDirectionLayer(route)
      isDirectionLayerLoaded.current = true
    }

    updateEstimatedTime(duration)
  }

  const addCourierPosition = async (): Promise<Marker | null> => {
    let marker = null
    const { connectionToken, deliveryId: shipmentId } = trackingData || {}
    if (connectionToken && shipmentId) {
      const { coordinates } = await tracking.location({ connectionToken, shipmentId })
      if (coordinates && toMarker.current) {
        marker = markerFactory({ kind: 'truck', coordinates })
        if (marker) {
          mapAPI.addMarker(marker)
          updateDirection({ from: marker, to: toMarker.current })
          courierMarker.current = marker
        }
      }

      return marker
    }

    return null
  }

  const isUpdateDirectionNeeded = async (): Promise<boolean> => {
    const { connectionToken, deliveryId: shipmentId } = trackingData || {}
    let didItMove = false

    if (connectionToken && shipmentId) {
      const { coordinates } = await tracking.location({ connectionToken, shipmentId })
      if (coordinates && toMarker?.current && courierMarker?.current) {
        didItMove = didCourierMove({ marker: courierMarker.current, coordinates })
        courierMarker?.current.setLngLat(formatCoordinates.toArray(coordinates))
      }
    }

    return didItMove
  }

  const updateCourierPosition = async (): Promise<void> => {
    if (!courierMarker?.current) {
      addCourierPosition()
    } else {
      const isUpdateNeeded = await isUpdateDirectionNeeded()
      if (toMarker?.current && isUpdateNeeded) {
        updateDirection({ from: courierMarker.current, to: toMarker.current })
      } else {
        updateEstimatedTime()
      }
    }
  }

  useInterval(
    async () => updateCourierPosition(),
    trackingConfig.refreshInterval,
    useCourierPosition
  )

  useEffect(() => {
    if (status === 'ready') setStatus('pending')
    if (typeof window === 'undefined' || !container.current) return
    if (!map.current) mapAPI.initMap()

    if (!trackingData || trackingData.status === 'onGoing') {
      mapAPI.addDirectionLayer()
      isDirectionLayerLoaded.current = true
    }

    if (trackingData && !isDataLoaded.current) {
      const boundsMarker: Marker[] = []
      const { fromAddress, toAddress } = trackingData

      toMarker.current = markerFactory({ kind: 'to', coordinates: toAddress.coordinates })
      fromMarker.current = markerFactory({ kind: 'from', coordinates: fromAddress.coordinates })
      if (fromMarker.current) boundsMarker.push(fromMarker.current)
      if (toMarker.current) boundsMarker.push(toMarker.current)

      mapAPI.addMarker(toMarker.current)
      mapAPI.addMarker(fromMarker.current)
      if (boundsMarker.length) {
        const bounds = mapAPI.getBounds(boundsMarker)
        mapAPI.setBounds(bounds)
        mapAPI.addPositionBtn(bounds)
      }
      isDataLoaded.current = true
    }

    if (useCourierPosition) {
      addCourierPosition().then((marker) => {
        if (marker && toMarker?.current && fromMarker?.current) {
          const bounds = mapAPI.getBounds([fromMarker.current, toMarker.current, marker])
          mapAPI.setBounds(bounds)
        }
      })
    }

    if (trackingData?.status === 'delivered' && courierMarker.current) {
      courierMarker.current.remove()
      mapAPI.removeDirection()
    }

    setStatus('ready')
  }, [trackingData, useCourierPosition])

  return (
    <>
      {status === 'pending' ? <Loader overlay /> : null}
      <div ref={container} className="map-container" />
    </>
  )
}

export default TrackingMap
