import React, { ReactNode } from 'react'

import './DatetimeContainer.css'
import { useToggleClass } from 'hooks'
import Truck from 'assets/icons/truck.svg'

interface Props {
  smallScreen?: boolean
  classNames?: string
  children: ReactNode
}

const DatetimeContainer = ({ smallScreen = false, classNames = '', children }: Props) => {
  const isToggle = useToggleClass()

  if (smallScreen) {
    return (
      <div
        className={`datetime-container-sm fade-in down ${classNames} ${isToggle ? 'show' : null}`}
      >
        <img src={Truck} alt="truck" />
        <div className="pre-line">{children}</div>
      </div>
    )
  }

  return <div className={`datetime-container-lg pre-line ${classNames}`}>{children}</div>
}

export default DatetimeContainer
