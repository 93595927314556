import React from 'react'
import { useTranslation } from 'react-i18next'

import './Contact.css'
import PhoneIcon from 'assets/icons/phone.svg'
import MessageIcon from 'assets/icons/message.svg'
import { ITrackingConfig } from 'interfaces'

interface Props {
  contact: ITrackingConfig['contactInfos']
}

const Contact = ({ contact }: Props) => {
  const { t } = useTranslation()
  const number = contact.intlPhone.replace('+33', '0').match(/.{1,2}/g)

  return (
    <div className="contact-container border-top bg-main-color">
      <div className="fw-bold">{t('contact')} :</div>
      <a href={`mailto:${contact.email}`} className="td-none">
        <img src={MessageIcon} alt="Email" />
        {contact.email}
      </a>
      <a href={`tel:${contact.intlPhone}`} className="td-none fc-secondary">
        <img src={PhoneIcon} alt="phone" />
        {number ? number.join(' ') : contact.intlPhone}
      </a>
    </div>
  )
}

export default Contact
