import { ITrackingConfig } from 'interfaces'
import http from './http'

const BASE_URL = process.env.REACT_APP_CONFIG_URL
export const DEFAULT_CONFIG: ITrackingConfig = {
  enableCourierPosition: true,
  refreshInterval: 120000,
  contactInfos: {
    intlPhone: '+33141402200',
    email: 'contact@topchrono.fr'
  }
}

const get = (): Promise<ITrackingConfig> => {
  if (BASE_URL) {
    return http.get({ url: `${BASE_URL}/api/tracking-setting` }).then(({ data }) => data.attributes)
  }
  return Promise.resolve(DEFAULT_CONFIG)
}

export default {
  get
}
