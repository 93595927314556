import React, { CSSProperties, ReactNode, useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import dayjs from 'dayjs'

import './Tracking.css'
import { ITrackingConfig, ITrackingData } from 'interfaces'
import { useMediaBreakpointUp } from 'hooks'
import {
  InfosLargescreen,
  InfosSmallscreen,
  Courier,
  DatetimeContainer,
  Header,
  TrackingForm,
  Contact
} from './components'

interface Props {
  children: ReactNode
  trackingData: ITrackingData | undefined
  estimatedTime: string | undefined
  refreshTime: string | undefined
  contact: ITrackingConfig['contactInfos']
  updateTrackingData(data: ITrackingData): void
}

const Tracking = ({
  trackingData,
  estimatedTime,
  refreshTime,
  contact,
  updateTrackingData,
  children
}: Props) => {
  const { customStyles, courier } = trackingData || {}
  const [isActive, setActive] = useState(false)
  const mediaBreakpointUp = useMediaBreakpointUp()
  let startDate: string = ''
  let startHour: string = ''
  let endHour: string = ''
  let style

  if (trackingData) {
    const { startAt, endAt } = trackingData

    startDate = mediaBreakpointUp('xl')
      ? dayjs(startAt).format('DD/MM/YY')
      : dayjs(startAt).format('ddd D MMMM')
    startHour = dayjs(startAt).format('HH:mm')
    endHour = dayjs(endAt).format('HH:mm')

    if (customStyles) {
      const { customBgColor, customFontColor, customTimelineColor } = customStyles
      style = {
        fontFamily: 'Montserrat',
        '--custom-bg-color': customBgColor,
        '--custom-font-color': customFontColor,
        '--custom-timeline-color': customTimelineColor
      } as CSSProperties
    }
  }

  useEffect(() => setActive(true), [])

  return (
    <div className="tracking-container" style={style}>
      {trackingData ? (
        <>
          <Header customStyles={customStyles} />
          {!mediaBreakpointUp('xl') && (
            <DatetimeContainer classNames={estimatedTime ? 'estimated' : 'scheduled'} smallScreen>
              {estimatedTime ? (
                <Trans i18nKey="deliveryEstimated.smallScreen">
                  <span className="fw-semibold fs-big fc-estimated-time" />
                  {{ estimatedTime }}
                </Trans>
              ) : (
                <Trans i18nKey="deliveryDatetime.smallScreen">
                  <span className="fw-semibold" />
                  {{ startDate }}
                  {{ startHour }}
                  {{ endHour }}
                </Trans>
              )}
            </DatetimeContainer>
          )}
        </>
      ) : null}
      {children}
      {trackingData ? (
        <div className={`tracking-details fade-in up ${isActive ? 'show' : null}`}>
          {courier ? (
            <Courier courier={courier} status={trackingData.status} refreshTime={refreshTime} />
          ) : null}
          {mediaBreakpointUp('xl') ? (
            <InfosLargescreen
              trackingData={trackingData}
              estimatedTime={estimatedTime}
              scheduledTime={{ startDate, startHour, endHour }}
            >
              <Contact contact={contact} />
            </InfosLargescreen>
          ) : (
            <InfosSmallscreen
              trackingData={trackingData}
              estimatedTime={estimatedTime}
              scheduledTime={{ startDate, startHour, endHour }}
            >
              <Contact contact={contact} />
            </InfosSmallscreen>
          )}
        </div>
      ) : (
        <TrackingForm updateTrackingData={updateTrackingData} />
      )}
    </div>
  )
}

export default Tracking
