import React from 'react'
import { useTranslation } from 'react-i18next'

import './Courier.css'
import { ITrackingData } from 'interfaces'
import Avatar from '../avatar/Avatar'

interface Props {
  courier: {
    url?: string
    name: string
    company: string
  }
  refreshTime: string | undefined
  status: ITrackingData['status']
}
const Courier = ({ courier, status, refreshTime }: Props) => {
  const { url, name, company } = courier
  const { t } = useTranslation()

  return (
    <>
      {refreshTime ? (
        <div className="refreshTime fw-semibold">{t('refreshTime', { refreshTime })}</div>
      ) : null}
      <div className={`courier-container ${!refreshTime ? 'border-radius' : ''}`}>
        <Avatar url={status === 'onGoing' ? '' : url} />
        <div>
          <div className="fw-semibold">{name}</div>
          <div className="fc-light">{company}</div>
        </div>
      </div>
    </>
  )
}

export default Courier
