import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import fr from './fr.json'
import en from './en.json'

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: { order: ['navigator'] },
    resources: { en, fr },
    fallbackLng: 'fr'
  })

export default i18n
