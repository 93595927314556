import React from 'react'
import ReactDOM from 'react-dom/client'
import 'mapbox-gl/dist/mapbox-gl.css'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import reportWebVitals from './reportWebVitals'

import './locales/i18n'
import './locales/dayjs'
import './styles'
import App from './App'

import './assets/fonts/Montserrat-ExtraBold.ttf'
import './assets/fonts/Montserrat-SemiBold.ttf'
import './assets/fonts/Montserrat-Regular.ttf'
import './assets/fonts/PlusJakartaSans-Bold.ttf'
import './assets/fonts/PlusJakartaSans-SemiBold.ttf'
import './assets/fonts/PlusJakartaSans-Regular.ttf'

let appReady = Promise.resolve()

// Enable API mocking only in development
if (process.env.NODE_ENV === 'development' && !process.env.REACT_APP_API_URL) {
  const { worker } = require('./mocks/browser') // eslint-disable-line
  appReady = worker.start()
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://64e6335f32d24fb8aaafff6d84f69c6c@o1039756.ingest.sentry.io/6726611',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  })
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

appReady.then(() => {
  root.render(<App />)
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
