interface Params {
  longitude: number
  latitude: number
}

const toArray = ({ longitude, latitude }: Params): [number, number] => [longitude, latitude]
const toString = ({ longitude, latitude }: Params): string => [longitude, latitude].join(',')

export default {
  toArray,
  toString
}
