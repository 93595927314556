import React, { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import './AttachmentsContainer.css'

interface Props {
  children: ReactNode | ReactNode[]
  largeScreen?: boolean
}

const AttachmentsContainer: FC<Props> = ({ largeScreen = false, children }) => {
  const { t } = useTranslation()

  if (largeScreen) {
    return <div className="attachments-container">{children}</div>
  }

  return (
    <div className="attachments-container border-top bg-main-color">
      <div className="fw-bold">{t('attachments')} :</div>
      {children}
    </div>
  )
}

export default AttachmentsContainer
