import React, { useEffect, useState } from 'react'

import './Header.css'
import { ICustomStyles } from 'interfaces'
import DefaultLogo from 'assets/tc_logo_v2.svg'
import i18n from 'locales/i18n'
import { useTranslation } from 'react-i18next'

interface Props {
  customStyles: ICustomStyles | undefined
}

const LANGUAGE_LIST = ['en-EN', 'fr-FR']

const Header = ({ customStyles }: Props) => {
  const { t } = useTranslation()
  const [isLanguageOpen, setIsLanguageOpen] = useState(false)
  const [isShow, setIsShow] = useState(false)

  const toggleLocaleDropdown = () => setIsLanguageOpen(!isLanguageOpen)

  const changeLanguage = (lg: string) => {
    i18n.changeLanguage(lg)
    setIsLanguageOpen(false)
  }

  useEffect(() => setIsShow(true), [])

  return (
    <div className={`header-container fade-in down ${isShow ? 'show' : null}`}>
      <div className="dd-wrapper">
        <div />
        <div className="header-center">
          <img src={customStyles?.logo || DefaultLogo} alt="company logo" className="logo" />
        </div>
        <div className="header-end">
          <button
            type="button"
            className="unstyled-button locale-button"
            onClick={toggleLocaleDropdown}
          >
            <div className="custom-fill" />
          </button>
        </div>
        {isLanguageOpen && (
          <ul className="dd-language-options">
            {LANGUAGE_LIST.filter((lg) => lg !== i18n.language).map((lg) => (
              <li key={lg}>
                <button
                  type="button"
                  className="unstyled-button fw-semibold"
                  onClick={() => changeLanguage(lg)}
                >
                  {t(`languages.${lg}`)}
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

export default Header
