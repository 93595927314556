import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { withTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import i18n from 'locales/i18n'
import trackingAPI from 'api/tracking'
import configAPI, { DEFAULT_CONFIG } from 'api/config'
import { ITrackingData, ITrackingConfig } from 'interfaces'
import { useInterval } from 'hooks'
import { hasTrackingDataChanged } from 'utils'
import { Analytics, Error, Loader, Tracking, TrackingMap } from 'components'

interface Props {
  isTestMode?: boolean
}

const App = ({ isTestMode = false }: Props) => {
  const trackingId: string | undefined = window.location.pathname.split('/').filter(Boolean)[0]
  const durationRef = useRef(0)
  const [status, setStatus] = useState<'pending' | 'resolved' | 'rejected'>('pending')
  const [trackingConfig, setTrackingConfig] = useState<ITrackingConfig>(DEFAULT_CONFIG)
  const [trackingData, setTrackingData] = useState<ITrackingData | undefined>()
  const [estimatedTime, setEstimatedTime] = useState<string | undefined>()
  const [refreshTime, setRefreshTime] = useState<string | undefined>()

  const updateEstimatedTime = (newDuration?: number): void => {
    if (newDuration) {
      durationRef.current = newDuration
      setEstimatedTime(dayjs().add(newDuration, 'second').format('HH:mm'))
    } else if (durationRef.current) {
      setEstimatedTime(dayjs().add(durationRef.current, 'second').format('HH:mm'))
    }
  }

  const updateRefreshTime = (): void => setRefreshTime(dayjs().format('HH:mm'))

  const updateTrackingData = useCallback(
    (data: ITrackingData) => setTrackingData(data),
    [setTrackingData]
  )

  const isPollingNeeded = useMemo(
    () => !['delivered', 'closed'].includes(trackingData?.status || ''),
    [trackingData]
  )

  useEffect(() => {
    if (trackingData?.status === 'delivered' && estimatedTime) {
      setEstimatedTime(undefined)
    }
  }, [trackingData, estimatedTime])

  useEffect(() => {
    configAPI.get().then(setTrackingConfig)
  }, [])

  useEffect(() => {
    if (trackingId) {
      trackingAPI
        .infos({ id: trackingId })
        .then((data) => {
          if (data.status === 'closed') {
            setStatus('rejected')
          } else {
            setStatus('resolved')
            setTrackingData(data)
          }
        })
        .catch(() => setStatus('rejected'))
    } else {
      setStatus('resolved')
    }
  }, [])

  useInterval(
    async () => {
      if (trackingId && trackingData) {
        trackingAPI
          .infos({ id: trackingId, token: trackingData?.connectionToken })
          .then((data) => {
            if (hasTrackingDataChanged({ trackingData, newTrackingData: data })) {
              setTrackingData(data)
            }
            updateRefreshTime()
          })
          .catch()
      }
    },
    trackingConfig.refreshInterval,
    isPollingNeeded
  )

  if (status === 'resolved') {
    dayjs.locale(i18n.language)

    return (
      <>
        {process.env.NODE_ENV === 'production' && <Analytics />}
        <Tracking
          trackingData={trackingData}
          updateTrackingData={updateTrackingData}
          estimatedTime={estimatedTime}
          refreshTime={refreshTime}
          contact={trackingConfig.contactInfos}
        >
          <TrackingMap
            trackingData={trackingData}
            updateEstimatedTime={updateEstimatedTime}
            trackingConfig={trackingConfig}
            isTestMode={isTestMode}
          />
        </Tracking>
      </>
    )
  }

  if (status === 'rejected') return <Error />

  return <Loader />
}

export default withTranslation()(App)
