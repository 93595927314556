import React from 'react'
import { ITrackingData } from 'interfaces'

import './RefContainer.css'
import RefNumber from '../ref-number/RefNumber'

interface Props {
  trackingData: ITrackingData
}

const RefContainer = ({ trackingData }: Props) => (
  <div className="ref-container">
    {['deliveryId', 'trackingId'].map((key: string) =>
      trackingData[key] ? (
        <div key={key} className="pre-line ref-card">
          <RefNumber number={trackingData[key] as string} i18nKey={key} />
        </div>
      ) : null
    )}
  </div>
)

export default RefContainer
